<template>
    <div>
        <span v-if="!hasStatus">N/A</span>
        <Icon
            v-else
            :name="icon"
            :color="color" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    name: 'PlatformStatus',
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        }
    },
    computed: {
        hasStatus() {
            return this.item[this.platform] !== 'N/A';
        },
        icon() {
            return this.item[this.platform] === 'success' ? 'check-circle' : 'indicator-error';
        },
        color() {
            return this.item[this.platform] === 'success' ? 'green' : 'red';
        }
    }

};
</script>
